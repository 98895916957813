<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Rincian Pelaporan Proses</h6>
        </b-col>
        <b-col lg="6" cols="7" style="display:flex;justify-content:flex-end">
              <base-button  @click="back()">Kembali</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container v-if="isLoading">
      <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
    </b-container>

    <b-container fluid class="mt--5" v-if="!isLoading">
      <b-row>
        
        <!-- umum -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <div class="card-body">

                <ul class="list-group">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Perusahaan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.comp_name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Dibuat oleh</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.created_by }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Instalasi Proses</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.tahapan }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Bukti pengadaan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.tender_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>


                  <li class="list-group-item" v-if="listData.tahapan == 'Pengoperasian'">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Dokumen pemasangan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.pemasangan_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item" v-if="listData.tahapan == 'Pengoperasian'">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Foto Instalasi</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.pic_pemasangan_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item" v-if="listData.tahapan == 'Pengoperasian'">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Foto Sparing</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.pic_sparing_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item" v-if="listData.tahapan == 'Pemasangan'">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jadwal pemasangan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.jadwal_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item" v-if="listData.tahapan == 'Pemasangan'">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Pemenang Sparing</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.pemenang_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item" v-if="listData.tahapan == 'Pengoperasian'">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Perencanaan masa uji</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.commission_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item" v-if="listData.tahapan == 'Pengoperasian'">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Rencana pengoperasian uji</strong>
                      </div>
                      <div class="col-lg-9">
                        <span><a :href="getBlobsFile(listData.files.pengoperasian_file_id)" target="blank">Lihat File</a></span>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </template>
          </b-card>
        </b-col>


      </b-row>
    </b-container>



    

  </div>
</template>
<script>
import API from "../../../../api/base_url.js"
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import moment from 'moment'
import Modal from "@/components/Modal.vue";
import baseURL from "@/api/base_url_backend.js";


export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
    Modal
  },
  data() {
    return {
      isLoading:true,
      listData: {}
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getData() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`progress-reports/${this.$route.params.id}` , {headers})
      .then(({data:content}) => {
        this.listData = content.data
        this.isLoading = false
      })
      .catch((err) => {
        console.log(err);
      })
    },
    back() {
      this.$router.push("/pelaporan-progress");
    },
  },
};
</script>
<style scoped>

.mdl-contents {
overflow-y: auto;
max-height: 90%;
}
.mdl-header {
height: 2.5rem;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
column-gap: 1rem;
}
.mdl-header i {
font-size: 30px;
color: #00bcd4;
color: #FFFFFF;
}
.mdl-header p {
margin-bottom: 0;
font-size: 28px;
font-weight: 600;
text-transform: uppercase;
color: #000000;
}

.announcement-modal-backgrounds {
background: #ff9999;
}

</style>
